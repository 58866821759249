import axios from "axios";
// 创建一个错误
function errorCreate(msg) {
  const error = new Error(msg);
  throw error;
}
// https://abcweb.naaeus.com/

const api = "http://192.168.1.6:8066/";

// const api = "https://abcweb.shanghehost.com/";
// 创建一个 axios 实例
const service = axios.create({
  baseURL:
    process.env.NODE_ENV == "development"
      ? api
      : "https://abcweb.shanghehost.com/",
  timeout: 10000, // 请求超时时间
});

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response.data;
    // 这个状态码是和后端约定的
    const { code } = dataAxios;
    // 根据 code 进行判断
    if (code == 200) {
      return dataAxios;
    } else {
      // 有 code 代表这是一个后端接口 可以进行进一步的判断
      switch (code) {
        case 0:
          // [ 示例 ] code === 0 代表没有错误
          return dataAxios;
        case "xxx":
          // [ 示例 ] 其它和后台约定的 code
          errorCreate(`[ code: xxx ] ${dataAxios.msg}: ${response.config.url}`);
          break;
        default:
          // 不是正确的 code
          errorCreate(`${dataAxios.msg}: ${response.config.url}`);
          this.$message({
            type: "error",
            message: `${dataAxios.message}`,
          });

          break;
      }
    }

    // if(res.code == 201){
    //   window.location.href =  "/#/login";
    // }
  
  },
  (error) => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = "请求错误";
          break;
        case 401:
          error.message = "未授权，请登录";
          break;
        case 403:
          error.message = "拒绝访问";
          break;
        case 404:
          error.message = `请求地址出错: ${error.response.config.url}`;
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 500:
          alert(error.response.data.msg);
          break;
        case 501:
          error.message = "服务未实现";
          break;
        case 502:
          error.message = "网关错误";
          break;
        case 503:
          error.message = "服务不可用";
          break;
        case 504:
          error.message = "网关超时";
          break;
        case 505:
          error.message = "HTTP版本不受支持";
          break;
        default:
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default service;
